import React from 'react'
import styled from 'styled-components'
import { LabeledFieldProps, SandwichLabeledField } from './field'
import TextAreaAutoSize from 'react-autosize-textarea'

const TextInput = styled.input`
  font-size: 1em;
  font-family: inherit;
  margin: 0px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.15rem;
  outline: none;
  width: 100%;
  background-color: #fdfdfd;
  resize: vertical;

  &:hover {
    border-color: #ccc;
  }

  &:focus {
    border-color: #08f;
    background-color: #fff;
    box-shadow: 0 0 0 2px rgba(0, 136, 255, 0.25);
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    border: 1px solid #e9e9e9;
    border-top: 1px solid #ddd;
    background-color: #fcfcfc;
    box-shadow: none;
  }
`

const TextArea = TextInput.withComponent(TextAreaAutoSize)

type TextBoxProps = {
  value: string
  onValue(newValue: string): void
  isRequired?: boolean
  isEnabled: boolean
  autoFocus?: boolean
  isMultipleLines?: boolean
}

export class TextBox extends React.Component<TextBoxProps> {
  override render() {
    const {
      value,
      onValue,
      isRequired,
      isEnabled,
      autoFocus,
      isMultipleLines,
      ...inputProps
    } = this.props

    const props = {
      value,
      onChange: (e: any) => onValue(e.target.value),
      required: isRequired,
      disabled: !isEnabled,
      autoFocus,
      ...inputProps,
    }

    return isMultipleLines ? <TextArea {...props} /> : <TextInput {...props} />
  }
}

export function TextField(props: TextBoxProps & LabeledFieldProps) {
  const { title, description, noLabelWrap, noPadding, ...textboxProps } = props
  return (
    <SandwichLabeledField
      {...{
        title,
        description,
        isEnabled: props.isEnabled,
        noPadding,
        noLabelWrap,
      }}
    >
      <TextBox {...textboxProps} />
    </SandwichLabeledField>
  )
}
