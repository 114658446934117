import styled, { css } from 'styled-components'
import format from 'date-fns/format'
import distanceInWords from 'date-fns/formatDistance'
import { Pad } from './spacing'
import { ReactChild, ReactNode } from 'react'

export const Card = styled.div<{ noBorder?: boolean }>`
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 2px;

  ${(props) =>
    !props.noBorder &&
    css`
      border: 1px solid #ddd;
    `}

  &:last-child {
    margin-bottom: 0px;
  }
`

export const BigHeading = styled.h1`
  font-size: 2em;
  margin: 0px;
`

export const Heading = styled.h1`
  margin: 0px;
  font-size: 1.5em;
  font-weight: normal;
`

export const Subheading = styled.h2`
  margin: 0px;
  font-size: 1.25em;
  font-weight: normal;
`

export const Code = styled.code`
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
    sans-serif;
  font-size: 1em;
`

export const Paragraph = styled.p<{ centered?: boolean }>`
  margin: 0px;
  font-size: 1em;
  line-height: 1.3em;

  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `}
`

export const Link = styled.a`
  text-decoration: underline;
  font-size: 1em;
  cursor: pointer;
  color: #065aa3;
`

export function EmailLink({
  displayName,
  email,
}: {
  displayName: string
  email: string
}) {
  return (
    <ExternalLink
      {...{
        href: `mailto:${email}`,
        title: `Email ${displayName || email}`,
      }}
    >
      {email}
    </ExternalLink>
  )
}

export function ExternalLink({
  href,
  title,
  children,
}: {
  href: string
  title?: string
  children: ReactChild
}) {
  return (
    <Link
      {...{
        href,
        target: '_blank',
        rel: 'noopener noreferrer',
        title,
        children,
      }}
    />
  )
}

const PathInner = styled.span``

const PathSegment = styled.span`
  &:after {
    content: '/';
    padding: 0px 8px;
    opacity: 0.5;
  }

  &:last-child:after {
    display: none;
  }
`

type PathProps = {
  items: JSX.Element[]
}

export const Path = ({ items }: PathProps) => {
  return (
    <PathInner>
      {items.map((item, index) => (
        <PathSegment key={index}>{item}</PathSegment>
      ))}
    </PathInner>
  )
}

export const Section = styled.div`
  border-bottom: 1px solid #f4f4f4;

  &:last-child {
    border-bottom: none;
  }
`

export const CardSection = styled(Section)`
  padding: 20px;
`

type CardHeaderProps = {
  title: string
  description?: string
}

export function CardHeader({ title, description }: CardHeaderProps) {
  return (
    <CardSection>
      <Heading>{title}</Heading>
      {description && <Paragraph>{description}</Paragraph>}
    </CardSection>
  )
}

const AttributeTable = styled.table`
  width: 100%;
  border-spacing: 0px;

  tr {
    box-shadow: inset 0 -1px 0px #f7f7f7;

    &:last-child {
      box-shadow: none;
    }
  }

  th {
    padding: 10px 10px 10px 20px;
    font-weight: normal;
    text-align: right;
    font-size: 0.9em;
    color: #222;
    white-space: nowrap;
  }

  td {
    padding: 10px 20px 10px 10px;
    width: 100%;
  }
`

type AttributesProps = {
  attributes: { key: string; label: string; value: ReactNode }[]
}

export const Attributes = ({ attributes }: AttributesProps) => {
  return (
    <Section>
      <AttributeTable>
        <tbody>
          {attributes.map(({ key, label, value }) => (
            <tr key={key}>
              <th>{label}</th>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </AttributeTable>
    </Section>
  )
}

const Time = styled.time`
  white-space: nowrap;
`

export function DateTime({ date }: { date: Date }) {
  const relativeTime = getRelativeTime(date, new Date())
  const longDate = format(date, 'dddd, MMMM D, YYYY h:mm A')
  return <Time title={longDate}>{relativeTime}</Time>
}

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function getRelativeTime(date: Date, now: Date) {
  const relativeTime = capitalize(
    distanceInWords(now, date, { addSuffix: true })
  )

  /*
  const stripPrefixes = ['about ', 'over ', 'almost ']
  stripPrefixes.forEach(prefix => {
    if (relativeDate.startsWith(prefix)) {
      relativeDate = relativeDate.slice(prefix.length)
    }
  })

  if (relativeDate === 'less than a minute') {
    relativeDate = 'Now'
  }
  */

  return relativeTime
}

const InnerList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`

const ListItem = styled.li`
  display: block;
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }
`

type ListProps = {
  items: { key: string; value: JSX.Element }[]
}

export function List({ items }: ListProps) {
  return (
    <Pad>
      <InnerList>
        {items.map((item) => (
          <ListItem key={item.key}>{item.value}</ListItem>
        ))}
      </InnerList>
    </Pad>
  )
}
