import { PropsWithChildren, ReactChild, ReactElement, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Pad, Split, SplitPriority } from './spacing'

type FieldProps = {
  noPadding?: true
  noLabelWrap?: true
}

export const Field = ({
  noPadding,
  noLabelWrap,
  children,
}: PropsWithChildren<FieldProps>): ReactElement => {
  let result: ReactNode = children
  if (!noLabelWrap) {
    result = <label>{result}</label>
  }

  if (!noPadding) {
    result = <Pad>{result}</Pad>
  }

  return <>{result}</>
}

export const Title = styled.p<{ isEnabled: boolean }>`
  font-size: 1em;
  font-weight: bold;
  color: #111;
  margin: 0px;

  ${(props) =>
    props.isEnabled
      ? css`
          color: #111;
        `
      : css`
          color: #545454;
        `}
`

export const Description = styled.p`
  font-size: 0.9em;
  color: #444;
  margin: 0px;
`

const TitleAbove = styled(Title)`
  margin-bottom: 3px;
`

export const DescriptionBelow = styled(Description)`
  margin-top: 5px;
`

export type LabeledFieldProps = FieldProps & {
  title: ReactChild
  description?: ReactChild
}

export function SandwichLabeledField(
  props: PropsWithChildren<LabeledFieldProps>
): ReactElement {
  const { title, description, noPadding, children, noLabelWrap } = props

  return (
    <Field {...{ noPadding, noLabelWrap }}>
      <TitleAbove isEnabled>{title}</TitleAbove>
      {children}
      {description && <DescriptionBelow>{description}</DescriptionBelow>}
    </Field>
  )
}

const FieldAction = styled.div`
  padding-right: 10px;
`

export function SideLabeledField(
  props: PropsWithChildren<LabeledFieldProps & { isEnabled: boolean }>
): ReactElement {
  const { title, description, noPadding, noLabelWrap, isEnabled, children } =
    props
  return (
    <Field {...{ noPadding, noLabelWrap }}>
      <Split>
        <FieldAction>{children}</FieldAction>
        <SplitPriority>
          <Title {...{ isEnabled }}>{title}</Title>
          {description && <Description>{description}</Description>}
        </SplitPriority>
      </Split>
    </Field>
  )
}
