import { Dispatch, Disposer, Effect } from 'center/compiled/util/raj'
import { Subscription } from './raj-subscription'

export type SocketMsg =
  | { type: 'connected' }
  | { type: 'connection_error' }
  | { type: 'disconnected' }
  | { type: 'message'; data: string }

type SocketRef = {
  getSocket: () => WebSocket
  resetSocket: () => void
  listen(dispatch: Dispatch<SocketMsg>): Disposer
}

export function createSocketRef(connectionURL: string): SocketRef {
  let socket: WebSocket
  let allListeners: Dispatch<SocketMsg>[] = []

  function dispatch(msg: SocketMsg) {
    allListeners.forEach((l) => l(msg))
  }

  function onConnection() {
    dispatch({ type: 'connected' })
  }

  function onConnectionError() {
    // NOTE: The event emitted tells us absolutely nothing :)
    dispatch({
      type: 'connection_error',
    })
  }

  function onDisconnect() {
    dispatch({ type: 'disconnected' })
  }

  function onMessage(event: MessageEvent<string>) {
    dispatch({ type: 'message', data: event.data })
  }

  function listen(socket: WebSocket) {
    socket.addEventListener('open', onConnection)
    socket.addEventListener('error', onConnectionError)
    socket.addEventListener('close', onDisconnect)
    socket.addEventListener('message', onMessage)
  }

  function forget(socket: WebSocket) {
    socket.removeEventListener('open', onConnection)
    socket.removeEventListener('error', onConnectionError)
    socket.removeEventListener('close', onDisconnect)
    socket.removeEventListener('message', onMessage)
  }

  return {
    getSocket() {
      if (!socket) {
        socket = new window.WebSocket(connectionURL)
        listen(socket)
      }

      return socket
    },
    resetSocket() {
      if (socket) {
        forget(socket)
        socket.close()
      }

      socket = new window.WebSocket(connectionURL)
      listen(socket)
    },
    listen(listener) {
      allListeners.push(listener)

      return () => {
        allListeners = allListeners.filter((l) => l !== listener)
      }
    },
  }
}

export function sendMessage(ref: SocketRef, message: string): Effect<never> {
  return () => {
    ref.getSocket().send(message)
  }
}

export function reconnect(ref: SocketRef) {
  return () => {
    ref.resetSocket()
  }
}

export function makeSubscription(ref: SocketRef): Subscription<SocketMsg> {
  let cancel: Disposer

  return {
    effect(dispatch) {
      ref.getSocket()
      cancel = ref.listen(dispatch)
    },
    cancel() {
      ref.getSocket().close()
      cancel()
    },
  }
}
