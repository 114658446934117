import { Component, ReactNode } from 'react'

type DocumentTitleProps = { title: string; children: ReactNode }

export class DocumentTitle extends Component<DocumentTitleProps> {
  override componentDidMount() {
    this.setTitle(this.props.title, true)
  }

  override componentDidUpdate(prevProps: DocumentTitleProps) {
    if (this.props.title !== prevProps.title) {
      this.setTitle(this.props.title)
    }
  }

  override componentWillUnmount() {
    if (this.oldTitle) {
      window.document.title = this.oldTitle
    }
  }

  oldTitle?: string
  setTitle(title: string, initial?: true) {
    if (initial) {
      this.oldTitle = window.document.title
    }
    window.document.title = title
  }

  override render() {
    return this.props.children
  }
}
