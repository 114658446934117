import ReactDom from 'react-dom'
import { reactProgram } from '../util/raj-react'
// import debug from 'raj-web-debugger'
import { makeProgram } from '../programs/mad-hatter'

const apiURL = process.env['REACT_APP_API_URL']!

function replaceHTTPProtocolWithWebSocket(urlString: string) {
  const map: Record<string, string> = {
    'http:': 'ws:',
    'https:': 'wss:',
  }

  const url = new URL(urlString)
  const newProtocol = map[url.protocol]
  if (!newProtocol) {
    return
  }

  url.protocol = newProtocol
  return url.toString()
}

const webSocketURL = replaceHTTPProtocolWithWebSocket(apiURL)
if (!webSocketURL) {
  throw new Error('Failed to construct WebSocket URL')
}

const containerElement = document.getElementById('root')

let appProgram = makeProgram(webSocketURL)
// if (process.env.NODE_ENV !== 'production') {
//   appProgram = debug(appProgram)
// }

const Application = reactProgram(() => appProgram)

ReactDom.render(<Application />, containerElement)
