import { Effect } from 'center/compiled/util/raj'
import { Subscription } from './raj-subscription'

function getCurrentRoute() {
  return window.location.hash.slice(1)
}

function setCurrentRoute(route: string) {
  window.location.hash = route
}

function watchCurrentRoute(handler: () => void, enabled: boolean) {
  if (enabled) {
    window.addEventListener('hashchange', handler)
  } else {
    window.removeEventListener('hashchange', handler)
  }
}

type HashRouter = {
  emit(route: string): Effect<never>
  subscribe(): Subscription<string>
}

type Listener = {
  (route: string): void
}

type Disposer = {
  (): void
}

export function createHashChangeRouter(
  shouldPrime: boolean = false
): HashRouter {
  let listeners: Listener[] = []

  const handler = () => {
    const route = getCurrentRoute()
    listeners.forEach((l) => l(route))
  }

  const update = (newListeners: Listener[]) => {
    listeners = newListeners
    watchCurrentRoute(handler, listeners.length > 0)
  }

  function addListener(listener: Listener): Disposer {
    if (shouldPrime) {
      listener(getCurrentRoute())
    }

    update(listeners.concat(listener))
    return () => update(listeners.filter((l) => l !== listener))
  }

  return {
    emit: (route) => () => setCurrentRoute(route),
    subscribe() {
      let dispose: Disposer | undefined
      return {
        effect(dispatch) {
          dispose = addListener(dispatch)
        },
        cancel() {
          if (dispose) {
            dispose()
            dispose = undefined
          }
        },
      }
    },
  }
}
