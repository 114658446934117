import { withSubscriptions, mapSubscription } from '../../util/raj-subscription'
import {
  SocketMsg,
  makeSubscription,
  createSocketRef,
  reconnect,
  sendMessage,
} from '../../util/web-socket'
import { Button } from '../../views/button'
import { Form } from '../../views/form'
import { GlobalStyle } from '../../views/global'
import styled, { css } from 'styled-components'
import { Subscription } from '../../util/raj-subscription'
import { Change, Dispatch, Disposer, Effect } from 'center/compiled/util/raj'
import { absurd } from '../../util/exhaustiveness'
import { mapEffect, batchEffects } from 'center/compiled/util/raj-compose'
import alaskanHatSrc, {
  ReactComponent as AlaskanHat,
} from './hats/alaskan-hat.svg'
import auntieVisorSrc, {
  ReactComponent as AuntieVisor,
} from './hats/auntie-visor.svg'
import ballCapSrc, { ReactComponent as BallCap } from './hats/ball-cap.svg'
import boaterHatSrc, {
  ReactComponent as BoaterHat,
} from './hats/boater-hat.svg'
import cowboyHatSrc, {
  ReactComponent as CowboyHat,
} from './hats/cowboy-hat.svg'
import dunceCapSrc, { ReactComponent as DunceCap } from './hats/dunce-cap.svg'
import fezSrc, { ReactComponent as FezHat } from './hats/fez.svg'
import knitCapSrc, { ReactComponent as KnitCap } from './hats/knit-cap.svg'
import mongolianWrestlingHatSrc, {
  ReactComponent as MongolianWrestlingHat,
} from './hats/mongolian-wrestling-hat.svg'
import sombreroHatSrc, {
  ReactComponent as SombreroHat,
} from './hats/sombrero.svg'
import tinFoilHatSrc, {
  ReactComponent as TinFoilHat,
} from './hats/tin-foil-hat.svg'
import topHatSrc, { ReactComponent as TopHat } from './hats/top-hat.svg'
import wizardHatSrc, {
  ReactComponent as WizardHat,
} from './hats/wizard-hat.svg'
import { Pad, Split, SplitPriority } from '../../views/spacing'
import { TextBox } from '../../views/text-field'
import { CopyProvider } from '../../views/copy-field'
import { DocumentTitle } from '../../views/document-title'
import { ExternalLink, Paragraph } from '../../views/card'
import { createHashChangeRouter } from '../../util/hash-router'
import { Component, createRef, Fragment, ReactNode } from 'react'
import { Select } from '../../views/select'

const SplitBlock = styled.div`
  width: 5px;
  height: 5px;
`

function wait(ms: number): Effect<void> {
  return (dispatch) => {
    setTimeout(() => dispatch(), ms)
  }
}

function every(ms: number): Subscription<void> {
  let timer: NodeJS.Timer
  return {
    effect(dispatch) {
      timer = setInterval(() => dispatch(), ms)
    },
    cancel() {
      if (timer) {
        clearInterval(timer)
      }
    },
  }
}

type Size = { width: number; height: number }

type Hat =
  | 'alaskan-hat'
  | 'auntie-visor'
  | 'ball-cap'
  | 'boater-hat'
  | 'cowboy-hat'
  | 'dunce-cap'
  | 'fez'
  | 'knit-cap'
  | 'mongolian-wrestling-hat'
  | 'sombrero'
  | 'tin-foil-hat'
  | 'top-hat'
  | 'wizard-hat'
const hats: Hat[] = [
  'alaskan-hat',
  'auntie-visor',
  'ball-cap',
  'boater-hat',
  'cowboy-hat',
  'dunce-cap',
  'fez',
  'knit-cap',
  'mongolian-wrestling-hat',
  'sombrero',
  'tin-foil-hat',
  'top-hat',
  'wizard-hat',
]

const hatImageSrcDict: Record<Hat, string> = {
  'alaskan-hat': alaskanHatSrc,
  'auntie-visor': auntieVisorSrc,
  'ball-cap': ballCapSrc,
  'boater-hat': boaterHatSrc,
  'cowboy-hat': cowboyHatSrc,
  'dunce-cap': dunceCapSrc,
  fez: fezSrc,
  'knit-cap': knitCapSrc,
  'mongolian-wrestling-hat': mongolianWrestlingHatSrc,
  sombrero: sombreroHatSrc,
  'tin-foil-hat': tinFoilHatSrc,
  'top-hat': topHatSrc,
  'wizard-hat': wizardHatSrc,
}

function watchElementSize(elementId: string): Subscription<Size> {
  let listener: Disposer | undefined

  return {
    effect(dispatch) {
      listener = () => {
        const element = window.document.getElementById(elementId)
        if (!element) {
          return
        }

        const bounds = element.getBoundingClientRect()
        const { width, height } = bounds
        dispatch({ width, height })
      }

      window.addEventListener('resize', listener)

      setTimeout(listener)
    },
    cancel() {
      if (listener) {
        window.removeEventListener('resize', listener)
      }
    },
  }
}

function getVelocityMultiplierForTimeSinceGameStart(duration: number): number {
  const seconds = duration / 1000
  if (seconds > 80) {
    return 5
  }

  if (seconds > 60) {
    return 2
  }

  if (seconds > 40) {
    return 1.5
  }

  if (seconds > 20) {
    return 1.25
  }

  return 1
}

function clamp(x: number, min: number, max: number): number {
  return Math.max(min, Math.min(x, max))
}

type Minion = {
  x: number
  y: number
  vx: number
  vy: number
}

const minionCount = 200
const minions: number[] = new Array(minionCount).fill(0).map((_, i) => i)
const preGameDuration = 5 * 1000
const gameDuration = 90 * 1000

function makeNullPhysics(): Record<number, Minion> {
  const physics: Record<number, Minion> = {}
  for (const minion of minions) {
    physics[minion] = { x: 0, y: 0, vx: 0, vy: 0 }
  }
  return physics
}

function layoutMinionsInFrame(
  minionSize: Size,
  canvasViewPort: Size,
  physics: Record<number, Minion>
) {
  const cellWidth = minionSize.width * 2
  const cellHeight = minionSize.height * 2
  const maxRowSize = Math.floor(canvasViewPort.height / cellHeight)
  const maxColSize = Math.floor(canvasViewPort.width / cellWidth)

  const baseX =
    cellWidth / 2 + (canvasViewPort.width - maxColSize * cellWidth) / 2
  const baseY =
    cellHeight / 2 + (canvasViewPort.height - maxRowSize * cellHeight) / 2

  let rowIndex = 0
  let colIndex = 0

  for (const minion of minions) {
    const x = baseX + colIndex * cellWidth
    const y =
      baseY + rowIndex * cellHeight + (colIndex % 2 === 0 ? cellHeight / 2 : 0)

    physics[minion]!.x = x
    physics[minion]!.y = y

    colIndex++
    if (colIndex >= maxColSize) {
      colIndex = 0
      rowIndex++
    }
  }
}

function updatePhysics(
  entityMap: Record<string, Minion>,
  skipMap: Record<number, string>,
  minionSize: Size,
  canvasViewPort: Size,
  timeDelta: number,
  velocityMultiplier: number
) {
  const withMultiplier = (v: number) => v * timeDelta * velocityMultiplier
  const minHeight = minionSize.height * 1.5

  for (const id in entityMap) {
    if (skipMap[+id]) {
      continue
    }

    const physics = entityMap[id]!

    let newX = physics.x + withMultiplier(physics.vx)
    if (
      physics.vx !== 0 &&
      (newX < 0 || newX + minionSize.width >= canvasViewPort.width)
    ) {
      physics.vx = -physics.vx
      newX = clamp(physics.x, 0, canvasViewPort.width)
    } else {
      physics.vx = clamp(physics.vx + (Math.random() - 0.5) / 1000, -0.25, 0.25)
    }

    let newY = physics.y + withMultiplier(physics.vy)
    if (
      physics.vy !== 0 &&
      (newY < minHeight || newY + minionSize.height >= canvasViewPort.height)
    ) {
      physics.vy = -physics.vy
      newY = clamp(physics.y, minHeight, canvasViewPort.height)
    } else {
      physics.vy = clamp(physics.vy + (Math.random() - 0.5) / 1000, -0.25, 0.25)
    }

    physics.x = newX
    physics.y = newY
  }
}

type GameStage = 'lobby' | 'game'

type ActivePlayer = {
  type: 'player'
  id: string
  name: string
  hat: Hat
  addedAt: number
}

type GameSpectator = {
  type: 'spectator'
  id: string
  name: string
  addedAt: number
}

type GamePlayer = ActivePlayer | GameSpectator

type Model = {
  route: Route

  isConnecting: boolean
  isConnected: boolean
  didEverConnect: boolean
  connectionError?: boolean
  isRegistering: boolean
  reconnectionAttempts: number
  failedToConnect: boolean
  clientAlreadyInUse: boolean

  gameId?: string
  isLoadingGame: boolean
  isCreatingGame: boolean
  lastGameNotFound: boolean

  viewPort?: Size
  minionSize?: Size
  canvasViewPort?: Size
  minionPhysics?: Record<number, Minion>
  minions: Record<number, string>
  players: GamePlayer[]

  gameStage: GameStage
  playerId?: string
  gameHostId?: string
  isGameStarting: boolean
  gameStartedAt: number
  lastGameTick: DOMHighResTimeStamp
  canvasCtx?: CanvasRenderingContext2D
  gameFinishedAt: number | undefined

  editablePlayerName: string
  isUpdatingName: boolean
  isSelectingHat: boolean

  sampleHat: Hat
  editableGameCode: string
  isSubmittingGameCode: boolean
  invalidGameCode?: string

  isResettingGame: boolean

  playMode: 'elimination' | 'reclamation'
  isUpdatingPlayMode: boolean
  joinMode: 'spectate' | 'play'
  isUpdatingJoinMode: boolean

  isUpdatingPlayerList: boolean
}

type Msg =
  | { type: 'create_game' }
  | { type: 'start_game' }
  | { type: 'minion_click'; minionId: number }
  | { type: 'frame_resize'; size: Size }
  | { type: 'game_tick'; time: number }
  | { type: 'reconnect' }
  | { type: 'find_other_client' }
  | { type: 'socket_event'; socketMsg: SocketMsg }
  | { type: 'set_player_name'; name: string }
  | { type: 'submit_player_name' }
  | { type: 'select_hat'; hat: Hat }
  | { type: 'update_sample' }
  | { type: 'set_game_code'; gameCode: string }
  | { type: 'submit_game_code' }
  | { type: 'hash_changed'; hash: string }
  | { type: 'reset_game' }
  | { type: 'update_play_mode'; elimination: boolean }
  | { type: 'update_join_mode'; spectateFirst: boolean }
  | { type: 'drop_player'; playerId: string }
  | { type: 'promote_player'; playerId: string }
  | { type: 'update_canvas_ctx'; ctx: CanvasRenderingContext2D }

type ClientMsg = {}

function getRouteGameCode(route: Route): string | undefined {
  switch (route.type) {
    case 'home':
    case 'unknown':
      return
    case 'game':
      return route.gameCode
    default:
      absurd(route)
  }
}

type Route =
  | { type: 'home' }
  | { type: 'game'; gameCode: string }
  | { type: 'unknown' }

function parsePageRoute(): Route {
  const hash = window.location.hash

  if (hash === '#' || hash === '') {
    return { type: 'home' }
  }

  if (hash.startsWith('#/g/')) {
    return { type: 'game', gameCode: hash.slice(4) }
  }

  return { type: 'unknown' }
}

const maxConnectAttemptCount = 5

export function makeProgram(webSocketURL: string) {
  const socket = createSocketRef(webSocketURL)
  const router = createHashChangeRouter()

  const init: [Model] = [
    {
      route: parsePageRoute(),

      isConnecting: true,
      isConnected: false,
      didEverConnect: false,
      isLoadingGame: false,
      isRegistering: false,
      reconnectionAttempts: 0,
      failedToConnect: false,
      clientAlreadyInUse: false,

      isCreatingGame: false,
      lastGameNotFound: false,

      isGameStarting: false,
      gameStage: 'lobby',
      minions: {},
      players: [],
      gameStartedAt: 0,
      gameFinishedAt: undefined,
      lastGameTick: 0,
      editablePlayerName: '',
      isUpdatingName: false,
      isSelectingHat: false,

      sampleHat: 'fez',
      editableGameCode: '',
      isSubmittingGameCode: false,

      isResettingGame: false,

      playMode: 'elimination',
      isUpdatingPlayMode: false,
      joinMode: 'play',
      isUpdatingJoinMode: false,

      isUpdatingPlayerList: false,
    },
  ]

  function send(clientMsg: ClientMsg): Effect<never> {
    console.log('clientMsg', clientMsg)
    return sendMessage(socket, JSON.stringify(clientMsg))
  }

  function setUrlToGame(gameCode: string): Effect<never> {
    return () => {
      window.location.hash = `/g/${gameCode}`
    }
  }

  function update(msg: Msg, model: Model): Change<Msg, Model> {
    switch (msg.type) {
      case 'create_game':
        return [
          {
            ...model,
            isCreatingGame: true,
          },
          send({ type: 'create-game' }),
        ]
      case 'start_game':
        return [
          {
            ...model,
            isGameStarting: true,
          },
          send({ type: 'start-game', gameId: model.gameId }),
        ]
      case 'minion_click': {
        const clickedAt = Date.now()

        const newMinions = { ...model.minions }
        if (model.playerId) {
          newMinions[msg.minionId] = model.playerId
        }

        return [
          {
            ...model,
            minions: newMinions,
          },
          send({
            type: 'click-minion',
            gameId: model.gameId,
            minionId: msg.minionId,
            clickedAt,
          }),
        ]
      }
      case 'frame_resize': {
        const { width, height } = msg.size

        const viewPort = { width, height }
        const minionSize = {
          width: Math.floor(width / 40) * scale,
          height: Math.floor(height / 25) * scale,
        }

        const canvasViewPort = {
          width: width * scale,
          height: height * scale,
        }

        if (model.minionPhysics) {
          layoutMinionsInFrame(minionSize, canvasViewPort, model.minionPhysics)
          updatePhysics(
            model.minionPhysics,
            model.minions,
            minionSize,
            canvasViewPort,
            500,
            5
          )
          prepOffscreenHatCanvas(minionSize)
        }

        return [{ ...model, minionSize, viewPort, canvasViewPort }]
      }
      case 'game_tick': {
        const { lastGameTick } = model
        const time = (model.lastGameTick =
          msg.time + window.performance.timeOrigin)

        if (!(model.canvasViewPort && model.minionSize)) {
          return [model]
        }

        if (!model.minionPhysics) {
          const minionPhysics = makeNullPhysics()
          layoutMinionsInFrame(
            model.minionSize,
            model.canvasViewPort,
            minionPhysics
          )
          prepOffscreenHatCanvas(model.minionSize)

          const newModel = {
            ...model,
            minionPhysics,
          }

          return [newModel, drawEffect(newModel)]
        }

        const timeSinceStart = time - model.gameStartedAt
        if (timeSinceStart < preGameDuration) {
          return [model, drawEffect(model)]
        }

        if (timeSinceStart > preGameDuration + gameDuration) {
          return [model, drawEffect(model)]
        }

        const delta = time - (lastGameTick || 0)
        const velocityMultiplier = getVelocityMultiplierForTimeSinceGameStart(
          time - model.gameStartedAt - preGameDuration
        )

        const skipMap = model.playMode === 'elimination' ? model.minions : {}

        updatePhysics(
          model.minionPhysics,
          skipMap,
          model.minionSize,
          model.canvasViewPort,
          delta,
          velocityMultiplier
        )

        return [model, drawEffect(model)]
      }
      case 'update_canvas_ctx': {
        return [{ ...model, canvasCtx: msg.ctx }]
      }
      case 'reconnect':
        return [{ ...model, isConnecting: true }, reconnect(socket)]
      case 'find_other_client': {
        const secret = window.localStorage.getItem('secret')
        const gameCode = getRouteGameCode(model.route)

        return [
          model,
          send({ type: 'register-client', secret, gameCode, poke: true }),
        ]
      }
      case 'socket_event': {
        const { socketMsg } = msg
        console.log('socketMsg', socketMsg)

        switch (socketMsg.type) {
          case 'connected': {
            const secret = window.localStorage.getItem('secret')
            const gameCode = getRouteGameCode(model.route)

            return [
              {
                ...model,
                isConnecting: false,
                isConnected: true,
                didEverConnect: true,
                reconnectionAttempts: 0,
                failedToConnect: false,
                isRegistering: true,
              },
              send({ type: 'register-client', secret, gameCode }),
            ]
          }
          case 'connection_error': {
            return [
              {
                ...model,
                isConnected: false,
                connectionError: true,
              },
            ]
          }
          case 'disconnected': {
            const retryCount = model.reconnectionAttempts + 1
            if (retryCount >= maxConnectAttemptCount) {
              return [
                {
                  ...model,
                  failedToConnect: true,
                  isConnected: false,
                  isConnecting: false,
                },
              ]
            }

            const jitter = Math.floor(Math.random() * 200)
            const exponent = Math.pow(2, retryCount)
            const retryDelay = retryCount * 200 * exponent + jitter

            return [
              {
                ...model,
                isConnected: false,
                isConnecting: false,
                reconnectionAttempts: retryCount,
              },
              mapEffect(wait(retryDelay), () => ({ type: 'reconnect' })),
            ]
          }
          case 'message': {
            const rawMessage = socketMsg.data
            let serverMsg
            try {
              serverMsg = JSON.parse(rawMessage)
            } catch (error) {
              console.error('Failed to parse socket message', rawMessage)
              return [model]
            }

            console.log('serverMsg', serverMsg)

            switch (serverMsg.type) {
              case 'client-registered': {
                window.localStorage.setItem('secret', serverMsg.secret)

                const { playerId } = serverMsg
                const newModel = {
                  ...model,
                  isRegistering: false,
                  clientAlreadyInUse: false,
                  playerId,
                }

                const { route } = model
                switch (route.type) {
                  case 'home':
                  case 'unknown':
                    return [newModel]
                  case 'game': {
                    const { gameCode } = route
                    return [
                      { ...newModel, isLoadingGame: true },
                      batchEffects([send({ type: 'fetch-game', gameCode })]),
                    ]
                  }
                  default:
                    return absurd(route)
                }
              }
              case 'game-created': {
                const { gameCode } = serverMsg
                return [
                  {
                    ...model,
                    isCreatingGame: false,
                    isLoadingGame: true,
                    lastGameNotFound: false,
                  },
                  setUrlToGame(gameCode),
                ]
              }
              case 'game-status': {
                const currentPlayerOrSpectator = serverMsg.players.find(
                  (p: GamePlayer) => p.id === model.playerId
                )
                const editablePlayerName =
                  currentPlayerOrSpectator?.name || model.editablePlayerName

                return [
                  {
                    ...model,
                    isLoadingGame: false,
                    lastGameNotFound: false,
                    gameId: serverMsg.gameId,
                    gameStage: serverMsg.gameStage,
                    gameHostId: serverMsg.gameHostId,
                    playMode: serverMsg.gamePlayMode,
                    joinMode: serverMsg.gameJoinMode,
                    gameStartedAt: serverMsg.startedAt,
                    gameFinishedAt: serverMsg.finishedAt,
                    players: serverMsg.players,
                    minions: serverMsg.minions,
                    editablePlayerName,
                  },
                ]
              }
              case 'game-started': {
                return [
                  {
                    ...model,
                    isGameStarting: false,
                    gameStage: 'game',
                    players: serverMsg.players,
                    gameStartedAt: serverMsg.startedAt,
                    gameFinishedAt: undefined,
                  },
                ]
              }
              case 'game-updated': {
                return [
                  {
                    ...model,
                    minions: { ...model.minions, ...serverMsg.minions },
                    gameFinishedAt: serverMsg.finishedAt,
                  },
                ]
              }
              case 'client-player-not-found': {
                window.localStorage.removeItem('secret')
                return [{ ...model }, send({ type: 'register-client' })]
              }
              case 'client-player-in-use': {
                return [{ ...model, clientAlreadyInUse: true }]
              }
              case 'client-reveal-yourself': {
                return [
                  model,
                  () => {
                    const oldTitle = window.document.title
                    window.document.title = '>> Hello! << – Mad Hatter'
                    window.alert('Hello, were you looking for me? :)')
                    window.document.title = oldTitle
                  },
                ]
              }
              case 'player-updated': {
                const { updatedPlayer } = serverMsg
                const isSelf = updatedPlayer.id === model.playerId

                const existingPlayer = model.players.find(
                  (p) => p.id === updatedPlayer.id
                )

                const isListChange = existingPlayer
                  ? existingPlayer.type !== updatedPlayer.type
                  : false

                const isSelfNameChange =
                  isSelf &&
                  existingPlayer &&
                  existingPlayer.name !== updatedPlayer.name
                const isSelfHatChange =
                  isSelf &&
                  existingPlayer &&
                  'hat' in existingPlayer &&
                  existingPlayer.hat !== updatedPlayer.hat

                return [
                  {
                    ...model,
                    players: model.players
                      .filter((p) => p !== existingPlayer)
                      .concat(updatedPlayer),
                    isUpdatingPlayerList: isListChange
                      ? false
                      : model.isUpdatingPlayerList,
                    isUpdatingName: isSelfNameChange
                      ? false
                      : model.isUpdatingName,
                    editablePlayerName: isSelfNameChange
                      ? updatedPlayer.name
                      : model.editablePlayerName,
                    isSelectingHat: isSelfHatChange
                      ? false
                      : model.isSelectingHat,
                  },
                ]
              }
              case 'player-name-taken': {
                const existingPlayer = model.players.find(
                  (p) => p.id === model.playerId
                )

                if (!existingPlayer) {
                  return [{ ...model, isUpdatingName: false }]
                }

                // Reset to old name
                // TODO: consider adding error message
                return [
                  {
                    ...model,
                    isUpdatingName: false,
                    editablePlayerName: existingPlayer.name,
                  },
                ]
              }
              case 'player-hat-taken': {
                // TODO: consider validation error
                return [{ ...model, isSelectingHat: false }]
              }
              case 'game-not-found': {
                if (!model.isSubmittingGameCode) {
                  return [{ ...model, lastGameNotFound: true }]
                }

                return [
                  {
                    ...model,
                    isSubmittingGameCode: false,
                    invalidGameCode: model.editableGameCode,
                  },
                ]
              }
              case 'game-joined': {
                const { gameCode } = serverMsg

                return [
                  { ...model, lastGameNotFound: false },
                  setUrlToGame(gameCode),
                ]
              }
              case 'game-reset': {
                const gameCode = getRouteGameCode(model.route)

                return [
                  { ...model, isResettingGame: false },
                  gameCode ? send({ type: 'fetch-game', gameCode }) : undefined,
                ]
              }
              case 'game-join-mode-updated': {
                return [
                  {
                    ...model,
                    isUpdatingJoinMode: false,
                    joinMode: serverMsg.joinMode,
                  },
                ]
              }
              case 'game-play-mode-updated': {
                return [
                  {
                    ...model,
                    isUpdatingPlayMode: false,
                    playMode: serverMsg.playMode,
                  },
                ]
              }
              case 'player-list-not-updated': {
                return [
                  {
                    ...model,
                    isUpdatingPlayerList: false,
                  },
                ]
              }
              case 'click-rejected': {
                return [
                  {
                    ...model,
                    minions: {
                      ...model.minions,
                      [serverMsg.minionId]: serverMsg.currentPlayerId,
                    },
                  },
                ]
              }
              default:
                throw new Error(
                  `Unimplemented server message: ${serverMsg.type}`
                )
            }
          }
          default:
            return absurd(socketMsg)
        }
      }
      case 'set_player_name': {
        return [{ ...model, editablePlayerName: msg.name }]
      }
      case 'submit_player_name': {
        const newName = model.editablePlayerName.trim()

        const currentPlayer = model.players.find((p) => p.id === model.playerId)
        if (!newName || !currentPlayer || currentPlayer.name === newName) {
          return [model]
        }

        return [
          { ...model, isUpdatingName: true, invalidGameCode: undefined },
          send({
            type: 'update-player-name',
            gameId: model.gameId,
            name: newName,
          }),
        ]
      }
      case 'select_hat': {
        return [
          {
            ...model,
            isSelectingHat: true,
          },
          send({
            type: 'update-player-hat',
            gameId: model.gameId,
            hat: msg.hat,
          }),
        ]
      }
      case 'update_sample': {
        return [
          {
            ...model,
            sampleHat: hats[Math.floor(Math.random() * hats.length)]!,
          },
        ]
      }
      case 'set_game_code': {
        return [
          {
            ...model,
            editableGameCode: msg.gameCode,
          },
        ]
      }
      case 'submit_game_code': {
        return [
          { ...model, isSubmittingGameCode: true },
          send({ type: 'join-game', gameCode: model.editableGameCode }),
        ]
      }
      case 'hash_changed': {
        const route = parsePageRoute()
        const newModel = { ...model, route }

        switch (route.type) {
          case 'home':
          case 'unknown':
            return [
              {
                ...newModel,
                editableGameCode: '',
                isSubmittingGameCode: false,
              },
            ]
          case 'game':
            return [
              newModel,
              send({ type: 'fetch-game', gameCode: route.gameCode }),
            ]
          default:
            return absurd(route)
        }
      }
      case 'reset_game': {
        return [
          { ...model, isResettingGame: true, minionPhysics: undefined },
          send({
            type: 'replay-game',
            gameId: model.gameId,
          }),
        ]
      }
      case 'update_join_mode': {
        const joinMode = msg.spectateFirst ? 'spectate' : 'play'

        return [
          {
            ...model,
            isUpdatingJoinMode: true,
            joinMode: joinMode,
          },
          send({
            type: 'update-join-mode',
            gameId: model.gameId,
            joinMode,
          }),
        ]
      }
      case 'update_play_mode': {
        const playMode: Model['playMode'] = msg.elimination
          ? 'elimination'
          : 'reclamation'

        return [
          {
            ...model,
            isUpdatingPlayMode: true,
            playMode,
          },
          send({
            type: 'update-play-mode',
            gameId: model.gameId,
            playMode,
          }),
        ]
      }
      case 'drop_player': {
        return [
          { ...model, isUpdatingPlayerList: true },
          send({
            type: 'drop-player',
            gameId: model.gameId,
            playerId: msg.playerId,
          }),
        ]
      }
      case 'promote_player': {
        return [
          { ...model, isUpdatingPlayerList: true },
          send({
            type: 'promote-player',
            gameId: model.gameId,
            playerId: msg.playerId,
          }),
        ]
      }
      default:
        absurd(msg)
    }
  }

  const subscriptions = (model: Model) => ({
    socket: () =>
      mapSubscription(
        makeSubscription(socket),
        (socketMsg) =>
          ({
            type: 'socket_event',
            socketMsg,
          } as Msg)
      ),
    gameFrame:
      model.gameStage === 'game'
        ? () =>
            mapSubscription(
              watchElementSize('game-frame'),
              (size) =>
                ({
                  type: 'frame_resize',
                  size,
                } as Msg)
            )
        : undefined,
    gameLoop:
      model.gameStage === 'game'
        ? () =>
            mapSubscription(
              rafSub(120),
              (time) => ({ type: 'game_tick', time } as Msg)
            )
        : undefined,
    heroCarousel:
      model.route.type !== 'game' || model.lastGameNotFound
        ? () =>
            mapSubscription(
              every(200),
              () => ({ type: 'update_sample' } as Msg)
            )
        : undefined,
    hash: () =>
      mapSubscription(
        router.subscribe(),
        (hash) =>
          ({
            type: 'hash_changed',
            hash,
          } as Msg)
      ),
  })

  return withSubscriptions({ init, update, view, subscriptions })
}

function view(model: Model, dispatch: Dispatch<Msg>): ReactNode {
  ;(window as any).$model = model

  const gameCode = getRouteGameCode(model.route)

  return (
    <>
      <GlobalStyle />
      <DocumentTitle
        title={gameCode ? `Mad Hatter - ${gameCode}` : 'Mad Hatter'}
      >
        {hubView(model, dispatch)}
      </DocumentTitle>
    </>
  )
}

const BannerSpace = styled.div`
  padding: 1rem;
  background-color: #c53a3a;
  color: #fff;
  text-shadow: 0 0 1px #5f1715;
  width: 100%;
  box-shadow: inset 0 -1px 3px #911e1e;
  text-align: center;
`

// const BannerButton = styled.button`
//   background-color: rgba(255, 255, 255, 0.2);
//   color: rgba(255, 255, 255, 0.8);
//   border: 1px solid transparent;
//   font-family: inherit;
//   font-size: 1rem;
//   border-radius: 0.15rem;
//   padding: 0.5rem 0.75rem;
//   margin: 0 0.5rem;
//   outline: none;

//   box-shadow: inset 0 -1px 2px rgba(255, 255, 255, 0.1),
//     0 1px 2px rgba(0, 0, 0, 0.2);

//   &:hover {
//     background-color: rgba(255, 255, 255, 0.4);
//     color: #fff;
//   }

//   &:focus {
//     border-color: #08f;
//     box-shadow: 0 0 0 2px rgb(0 136 255 / 25%);
//   }
// `

function drawEffect(model: Model) {
  return () => {
    let ctx = model.canvasCtx
    // if (!ctx) {
    //   ctx = model.canvasCtx = findDrawingContext()
    // }

    if (ctx) {
      drawFrame(ctx, model)
    }
  }
}

const GamePort = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  max-height: 100%;
`

const InnerGamePort = styled(GamePort)`
  flex-direction: column;
`

function hubView(model: Model, dispatch: Dispatch<Msg>) {
  return (
    <Game>
      {model.clientAlreadyInUse && (
        <BannerSpace>
          <Form onSubmit={() => dispatch({ type: 'find_other_client' })}>
            Another browser tab or window has Mad Hatter running already. There
            can only be one!
            {/* <BannerButton>Find the other</BannerButton> */}
          </Form>
        </BannerSpace>
      )}
      <GamePort>{mainView(model, dispatch)}</GamePort>
    </Game>
  )
}

function mainView(model: Model, dispatch: Dispatch<Msg>) {
  const { route } = model
  switch (route.type) {
    case 'home':
      return homeView(model, dispatch)
    case 'game': {
      if (model.lastGameNotFound) {
        return gameNotFoundView(model, dispatch)
      }

      if (model.isLoadingGame) {
        return 'Loading...'
      }

      switch (model.gameStage) {
        case 'lobby':
          return lobbyView(model, dispatch)
        case 'game':
          return gameView(model, dispatch)
        default:
          return absurd(model.gameStage)
      }
    }
    case 'unknown':
      return pageNotFoundView(model, dispatch)
    default:
      return absurd(route)
  }
}

function pageNotFoundView(model: Model, dispatch: Dispatch<Msg>) {
  return (
    <InnerGamePort>
      <BannerSpace>
        <b>Error: Page not found</b>, so here's the homepage.
      </BannerSpace>

      <GamePort>{homeView(model, dispatch)}</GamePort>
    </InnerGamePort>
  )
}

function gameNotFoundView(model: Model, dispatch: Dispatch<Msg>) {
  return (
    <InnerGamePort>
      <BannerSpace>
        <b>Error: Game not found</b>, so here's the homepage.
      </BannerSpace>

      <GamePort>{homeView(model, dispatch)}</GamePort>
    </InnerGamePort>
  )
}

const LobbyPlayerList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const LobbyPlayer = styled.li`
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LobbyPlayerName = styled.p`
  flex: 1;
  margin: 0px;
  padding: 0 1rem;
  font-size: 1.5rem;
`

const LobbySpectatorName = styled.p`
  flex: 1;
  margin: 0px;
  font-size: 1.5em;
`

const HatList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`

const HatCell = styled.li<{ isSelected: boolean; isEnabled: boolean }>`
  display: inline-block;
  padding: 1em;
  margin: 0 1em 1em 0;
  border-radius: 2px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #e8f4ff;
      border-color: #08f;
    `}

  ${(props) =>
    props.isEnabled
      ? props.isSelected
        ? undefined
        : css`
            cursor: pointer;

            &:hover {
              background-color: #eff6fc;
              border-color: #77b9f3;
            }
          `
      : css`
          opacity: 0.5;
          cursor: not-allowed;
          box-shadow: none;
          background-color: #ddd;
        `}
`

const HeroHat = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  transform: rotate(4deg);
  z-index: 2;
`

const TinyParagraph = styled.p`
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
`

type ConnectionColor = 'green' | 'red' | 'gray'
type ConnectionState = {
  text: string
  color: ConnectionColor
}

function getConnectionState(model: Model): ConnectionState {
  if (model.isConnected) {
    return { text: 'Connected ✓', color: 'green' }
  }

  if (model.isConnecting) {
    if (model.didEverConnect) {
      return { text: 'Reconnecting…', color: 'gray' }
    }

    return { text: 'Connecting…', color: 'gray' }
  }

  if (model.failedToConnect) {
    if (model.didEverConnect) {
      return { text: 'Reconnect failed', color: 'red' }
    }

    return { text: 'Connection failed', color: 'red' }
  }

  if (model.connectionError) {
    if (model.reconnectionAttempts > 0) {
      return {
        text: `Connection error (retry ${model.reconnectionAttempts}/${maxConnectAttemptCount})`,
        color: 'red',
      }
    }

    return { text: 'Connection error', color: 'red' }
  }

  return { text: 'Connection unknown', color: 'gray' }
}

const textColorMap = {
  green: '#0a6720',
  red: '#cb2431',
  gray: '#555',
}

const ConnectionStatus = styled.p<{ color: ConnectionColor }>`
  ${(props) => {
    const color = textColorMap[props.color]
    return css`
      color: ${color};
    `
  }}
`

const Hero = styled.div`
  position: relative;
  margin: 2em 0;

  h1 {
    font-size: 4em;
    margin: 0px;
  }

  ${ConnectionStatus} {
    margin: 0;
    text-align: right;
  }
`

const SplitRule = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: 1.5em 3em;
`

const Footer = styled.div`
  padding-top: 1rem;
`

const ErrorText = styled.p`
  font-size: 0.8em;
  color: #d51d1d;
  margin: 0;
  padding-top: 3px;
`

function homeView(model: Model, dispatch: Dispatch<Msg>) {
  const state = getConnectionState(model)

  return (
    <div>
      <Hero>
        <HeroHat>
          <HatIcon type={model.sampleHat} size={64} />
        </HeroHat>
        <h1>Mad Hatter</h1>
        <ConnectionStatus color={state.color}>{state.text}</ConnectionStatus>
      </Hero>
      <Group>
        <Sec>
          <Split verticalCenter>
            <SplitPriority>
              <span>Join a game:</span>
            </SplitPriority>
            <Form onSubmit={() => dispatch({ type: 'submit_game_code' })}>
              <Split>
                <div style={{ maxWidth: 80 }}>
                  <TextBox
                    {...{
                      autoFocus: true,
                      value: model.editableGameCode,
                      onValue(gameCode) {
                        dispatch({ type: 'set_game_code', gameCode })
                      },
                      isEnabled:
                        !!model.playerId && !model.isSubmittingGameCode,
                      isRequired: true,
                    }}
                  />
                </div>
                <SplitBlock />
                <Button
                  {...{
                    title: 'Join',
                    type: 'submit',
                    isEnabled:
                      !!model.playerId &&
                      // NOTE: We don't want this validation
                      // because it looks like the whole input+submit
                      // is broken and can't be used.
                      // New users won't know it needs a four digit code.
                      // model.editableGameCode.length === 4 &&
                      !model.isSubmittingGameCode,
                  }}
                />
              </Split>
            </Form>
          </Split>
          {model.invalidGameCode && (
            <ErrorText>
              Game not found for code "{model.invalidGameCode}"
            </ErrorText>
          )}
          <SplitRule />
          <Split verticalCenter>
            <SplitPriority>
              <span>Host a new game:</span>
            </SplitPriority>
            <Button
              {...{
                color: 'blue',
                title: 'Create game',
                isEnabled: !!(model.playerId && !model.isCreatingGame),
                onClick() {
                  dispatch({ type: 'create_game' })
                },
              }}
            />
          </Split>
        </Sec>
      </Group>

      <Footer>
        <Pad>
          <Paragraph centered>
            Written by{' '}
            <ExternalLink href="https://jew.ski">
              Chris Andrejewski
            </ExternalLink>
            .
            <br />
            All the rights reserved.
          </Paragraph>
        </Pad>
        <Pad>
          <TinyParagraph>
            <ExternalLink href="https://www.buymeacoffee.com/andrejewski">
              Buy me a bag of carrots
            </ExternalLink>{' '}
            🥕
          </TinyParagraph>
        </Pad>
      </Footer>
    </div>
  )
}

function HatOption({
  type,
  isSelected,
  isEnabled,
  onClick,
}: {
  type: Hat
  isSelected: boolean
  isEnabled: boolean
  onClick: () => void
}) {
  return (
    <HatCell
      {...{
        isSelected,
        isEnabled,
        onClick() {
          if (!isSelected && isEnabled) {
            onClick()
          }
        },
      }}
    >
      <HatIcon size={64} type={type} />
    </HatCell>
  )
}

const PlayerEditor = styled.div`
  flex: 1;
  align-self: stretch;
  overflow-y: auto;
  background-color: #fff;
  padding: 1em;
  border-left: 1px solid #eee;

  p {
    margin: 1rem 0 0.5rem;
  }

  p:first-child {
    margin-top: 0.5rem;
  }
`

const Lobby = styled.div`
  padding: 1em;
  flex: 1;
  align-self: stretch;
  overflow-y: auto;

  ${ConnectionStatus} {
    margin: 0px;
  }
`

const Sec = styled.div`
  padding: 1em;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 1em 0;
`

const SecText = styled.p`
  margin: 0 0 1em;

  &:last-child {
    margin: 0;
  }
`

const Group = styled.div`
  margin-bottom: 1em;
`

const GroupTitle = styled.div`
  margin: 0.5em 0;

  h2 {
    margin: 0;
  }
`

const CopyableCode = styled.span`
  cursor: pointer;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  background-color: #e6fbff;

  &:hover {
    background-color: #c2dff9;
  }

  &:active {
    color: #fff;
    background-color: #08f;
  }
`

// const GameCode = styled.span`
//   padding-right: 0.25em;
// `

const Hint = styled.span`
  font-size: 0.9em;
  opacity: 0.8;
`

const LobbyTitle = styled.h1`
  margin: 0px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

function playerComparator(a: GamePlayer, b: GamePlayer): number {
  return a.addedAt - b.addedAt
}

function splitPlayersAndSpectators(
  allPlayers: GamePlayer[]
): [ActivePlayer[], GameSpectator[]] {
  const activePlayers: ActivePlayer[] = []
  const spectators: GameSpectator[] = []

  for (const player of allPlayers) {
    switch (player.type) {
      case 'player':
        activePlayers.push(player)
        break
      case 'spectator':
        spectators.push(player)
        break
      default:
        absurd(player)
    }
  }

  activePlayers.sort(playerComparator)
  spectators.sort(playerComparator)
  return [activePlayers, spectators]
}

function makePlayerTag(
  player: GamePlayer,
  gameHost: GamePlayer | undefined,
  currentPlayer: GamePlayer | undefined
): string | undefined {
  const isSelf = player.id === currentPlayer?.id
  const isHost = player.id === gameHost?.id

  if (isSelf && isHost) {
    return '(you are host)'
  }

  if (isHost) {
    return '(host)'
  }

  if (isSelf) {
    return '(you)'
  }

  return
}

function lobbyView(model: Model, dispatch: Dispatch<Msg>) {
  const currentPlayerOrSpectator = model.players.find(
    (p) => p.id === model.playerId
  )
  const gameHost = model.players.find((p) => p.id === model.gameHostId)
  const isHost = model.playerId === model.gameHostId

  const [players, spectators] = splitPlayersAndSpectators(model.players)
  const hasEnoughPlayers = players.length >= 2

  const currentPlayer = players.find((p) => p.id === model.playerId)
  const otherPlayerHats = players
    .filter((p) => p !== currentPlayer)
    .map((p) => p.hat)

  const homeLink = '#'

  const state = getConnectionState(model)

  return (
    <>
      <SplitPriority stretch>
        <Lobby>
          <Group>
            <Split verticalCenter>
              <SplitPriority>
                <LobbyTitle>
                  <a href={homeLink}>Mad Hatter</a>
                </LobbyTitle>
              </SplitPriority>
              <ConnectionStatus color={state.color}>
                {state.text}
              </ConnectionStatus>
            </Split>

            <Sec>
              <Split verticalCenter>
                <SplitPriority>
                  <SecText>
                    <CopyProvider<HTMLSpanElement> value={window.location.href}>
                      {(copyProps) => (
                        <CopyableCode {...copyProps}>
                          Game code: <b>{getRouteGameCode(model.route)}</b>{' '}
                          <Hint>(click to copy link)</Hint>
                        </CopyableCode>
                      )}
                    </CopyProvider>
                  </SecText>

                  {gameHost && (
                    <SecText>
                      {isHost ? 'You' : gameHost.name} can start the game{' '}
                      {players.length >= 2
                        ? 'whenever you all are ready!'
                        : players.length === 1
                        ? 'once you have at least one more player.'
                        : 'once you have at least two players.'}
                    </SecText>
                  )}
                  <Split verticalCenter horizontal="pack">
                    <Paragraph>Play mode: </Paragraph>
                    <SplitBlock />
                    <Select
                      {...{
                        size: 'compact',
                        isEnabled: isHost && !model.isUpdatingPlayMode,
                        value: model.playMode,
                        options: [
                          {
                            value: 'reclamation',
                            title: 'Reclamation',
                          },
                          {
                            value: 'elimination',
                            title: 'Elimination',
                          },
                        ],
                        onValue(playMode) {
                          dispatch({
                            type: 'update_play_mode',
                            elimination: playMode === 'elimination',
                          })
                        },
                      }}
                    />
                    {!isHost && (
                      <>
                        <SplitBlock />
                        <Paragraph>(host can change)</Paragraph>
                      </>
                    )}
                  </Split>
                </SplitPriority>
                <SplitBlock />
                <Button
                  {...{
                    title: 'Start game →',
                    isEnabled:
                      isHost && hasEnoughPlayers && !model.isGameStarting,
                    onClick() {
                      dispatch({ type: 'start_game' })
                    },
                  }}
                />
              </Split>
            </Sec>
          </Group>

          <Group>
            <GroupTitle>
              <Split verticalCenter>
                <SplitPriority>
                  <h2>
                    Players ({players.length}/{hats.length})
                  </h2>
                </SplitPriority>
                <SplitBlock />
                <Select
                  {...{
                    size: 'compact',
                    isEnabled: isHost,
                    value: model.joinMode,
                    options: [
                      {
                        value: 'play',
                        title: 'New players join automatically',
                      },
                      {
                        value: 'spectate',
                        title: 'New players join as spectators',
                      },
                    ],
                    onValue(joinMode) {
                      dispatch({
                        type: 'update_join_mode',
                        spectateFirst: joinMode === 'spectate',
                      })
                    },
                  }}
                />
              </Split>
            </GroupTitle>

            {players.length > 0 ? (
              <LobbyPlayerList>
                {players.map((player) => (
                  <LobbyPlayer key={player.id}>
                    <HatIcon size={32} type={player.hat} />
                    <LobbyPlayerName>
                      <b>{player.name} </b>
                      {makePlayerTag(
                        player,
                        gameHost,
                        currentPlayerOrSpectator
                      )}
                    </LobbyPlayerName>
                    {(isHost || player.id === currentPlayer?.id) && (
                      <Button
                        {...{
                          size: 'compact',
                          title: 'Drop',
                          isEnabled: !model.isUpdatingPlayerList,
                          onClick() {
                            dispatch({
                              type: 'drop_player',
                              playerId: player.id,
                            })
                          },
                        }}
                      />
                    )}
                  </LobbyPlayer>
                ))}
              </LobbyPlayerList>
            ) : (
              <Sec>
                <SecText>There are no players.</SecText>
              </Sec>
            )}
          </Group>

          {(spectators.length > 0 || model.joinMode === 'spectate') && (
            <Group>
              <GroupTitle>
                <h2>Spectators ({spectators.length})</h2>
              </GroupTitle>
              {spectators.length > 0 ? (
                <LobbyPlayerList>
                  {spectators.map((spectator) => (
                    <LobbyPlayer key={spectator.id}>
                      <LobbySpectatorName>
                        <b>{spectator.name} </b>
                        {makePlayerTag(
                          spectator,
                          gameHost,
                          currentPlayerOrSpectator
                        )}
                      </LobbySpectatorName>
                      {isHost && (
                        <Button
                          {...{
                            size: 'compact',
                            title: 'Promote',
                            isEnabled:
                              !model.isUpdatingPlayerList &&
                              players.length < hats.length,
                            onClick() {
                              dispatch({
                                type: 'promote_player',
                                playerId: spectator.id,
                              })
                            },
                          }}
                        />
                      )}
                    </LobbyPlayer>
                  ))}
                </LobbyPlayerList>
              ) : (
                <Sec>
                  <SecText>There are no spectators.</SecText>
                </Sec>
              )}
            </Group>
          )}
        </Lobby>
      </SplitPriority>
      <SplitPriority stretch>
        <PlayerEditor>
          {currentPlayerOrSpectator && (
            <>
              <p>Your player name is:</p>
              <Form onSubmit={() => dispatch({ type: 'submit_player_name' })}>
                <div style={{ maxWidth: 320 }}>
                  <Split>
                    <SplitPriority>
                      <TextBox
                        {...{
                          value: model.editablePlayerName,
                          onValue(name) {
                            dispatch({
                              type: 'set_player_name',
                              name: name.slice(0, 30),
                            })
                          },
                          isEnabled: !model.isUpdatingName,
                          isRequired: true,
                        }}
                      />
                    </SplitPriority>
                    <SplitBlock />
                    <Button
                      {...{
                        type: 'submit',
                        title: 'Update',
                        isEnabled:
                          !model.isUpdatingName &&
                          model.editablePlayerName.trim().length > 0,
                      }}
                    />
                  </Split>
                </div>
              </Form>
            </>
          )}
          {
            <>
              <p>
                {currentPlayer
                  ? 'Your hat is:'
                  : "You're spectating right now and spectators can't choose hats"}
              </p>
              <HatList>
                {hats.map((type) => (
                  <HatOption
                    {...{
                      key: type,
                      type,
                      isSelected: currentPlayer
                        ? currentPlayer.hat === type
                        : false,
                      isEnabled:
                        !!currentPlayer && !otherPlayerHats.includes(type),
                      onClick() {
                        if (!model.isSelectingHat) {
                          dispatch({ type: 'select_hat', hat: type })
                        }
                      },
                    }}
                  />
                ))}
              </HatList>
            </>
          }
        </PlayerEditor>
      </SplitPriority>
    </>
  )
}

const Header = styled.h1`
  margin: 0.5em;
  padding: 0px;
  text-align: center;
`

const Game = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Main = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 1em;
  display: flex;
`

const Side = styled.div`
  background-color: #fff;
  border-left: 1px solid #eee;
  align-self: stretch;
  width: 20vw;
`

const TimeBox = styled.div`
  border: 1px solid rgb(48 106 175 / 80%);
  border-radius: 3px;
  position: relative;
  margin: 0.5em;
  text-align: center;

  label {
    display: block;
    padding: 0.25em 0.5em 0 0.5em;
    color: rgba(0, 0, 0, 0.8);
  }
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgb(171 204 254 / 50%);
`

const Timer = styled.time`
  display: block;
  font-family: monospace;
  font-size: 2em;
  font-weight: bold;
  padding: 0 0.5em 0.25em 0.5em;

  color: rgba(0, 0, 0, 0.5);
  b {
    color: #000;
  }
`

const PlayerList = styled.div`
  border-top: 1px solid #ddd;
`

const PlayerItem = styled.div`
  border-bottom: 1px solid #eee;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
`

const PlayerNameBox = styled.div`
  flex: 1;
  min-width: 0;
`

const PlayerName = styled.p`
  flex: 1;
  margin: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const PlayerScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Plus = styled.div`
  padding: 0.25em;
  font-size: 0.8em;
  color: #555;
`

function HatIcon({ type, size }: { type: Hat; size: number }) {
  const props = {
    className: 'HatIcon',
    width: size,
    height: size,
  }

  switch (type) {
    case 'alaskan-hat':
      return <AlaskanHat {...props} />
    case 'auntie-visor':
      return <AuntieVisor {...props} />
    case 'ball-cap':
      return <BallCap {...props} />
    case 'boater-hat':
      return <BoaterHat {...props} />
    case 'cowboy-hat':
      return <CowboyHat {...props} />
    case 'dunce-cap':
      return <DunceCap {...props} />
    case 'fez':
      return <FezHat {...props} />
    case 'knit-cap':
      return <KnitCap {...props} />
    case 'mongolian-wrestling-hat':
      return <MongolianWrestlingHat {...props} />
    case 'sombrero':
      return <SombreroHat {...props} />
    case 'tin-foil-hat':
      return <TinFoilHat {...props} />
    case 'top-hat':
      return <TopHat {...props} />
    case 'wizard-hat':
      return <WizardHat {...props} />
    default:
      absurd(type)
  }
}

function PlayerListItem({
  name,
  hat,
  score,
  leader,
}: {
  name: string
  hat: Hat
  score: number
  leader: boolean
}) {
  return (
    <PlayerItem>
      <PlayerNameBox>
        <PlayerName>{leader ? <b>{name}</b> : name}</PlayerName>
      </PlayerNameBox>
      <PlayerScore>
        <b>{score}</b> <Plus>×</Plus> <HatIcon size={32} type={hat} />
      </PlayerScore>
    </PlayerItem>
  )
}

const GameFrame = styled.div`
  position: relative;
  align-self: stretch;
  flex: 1;

  .Minion {
    display: flex;
    position: absolute;
  }
`

const clock = (s: number) => Math.floor(s).toString().padStart(2, '0')

const ModalBackdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const FadeInDialog = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 50px 50px rgba(255, 255, 255, 0.3);
  border-radius: 10%;
  max-width: 60%;
`

const DialogContent = styled.div`
  padding: 1em;
  font-size: 1.5em;
  text-align: center;

  p {
    margin: 0;
  }

  .HatIcon {
    padding-bottom: 0.5em;
  }
`
const DialogFooter = styled.div`
  padding: 1em;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
`

function CountdownClock({
  timeRemaining,
  showMinutes,
}: {
  timeRemaining: number
  showMinutes: boolean
}) {
  const minutes = Math.floor(timeRemaining / (60 * 1000))
  const minutesInMs = minutes * 60 * 1000
  const seconds = Math.floor((timeRemaining - minutesInMs) / 1000)
  const secondsInMs = seconds * 1000
  const subSeconds = Math.floor(
    (timeRemaining - minutesInMs - secondsInMs) / 10
  )

  return (
    <Timer>
      <b>
        {showMinutes ? <>{clock(minutes)}:</> : undefined}
        {clock(seconds)}
      </b>
      :{clock(subSeconds)}
    </Timer>
  )
}

function gameOverContent(
  players: ActivePlayer[],
  playerStats: Record<string, number>
) {
  const highScore = Math.max(0, ...Object.values(playerStats))
  if (!highScore) {
    return (
      <DialogContent>
        <p>No one wins…no one hatted :(</p>
      </DialogContent>
    )
  }

  const winners = players.filter((p) => playerStats[p.id] === highScore)
  if (winners.length === 1) {
    const winner = winners[0]!
    return (
      <DialogContent>
        <HatIcon type={winner.hat} size={64} />
        <p>
          <b>{winner.name}</b> wins with <b>{highScore}</b> hats!
        </p>
      </DialogContent>
    )
  }

  return (
    <DialogContent>
      <p>
        {winners.map((p, index, list) =>
          index > 0 ? (
            <Fragment key={p.id}>
              {index + 1 === list.length ? ' and' : ','} <b>{p.name}</b>
            </Fragment>
          ) : (
            <b key={p.id}>{p.name}</b>
          )
        )}{' '}
        tie with <b>{highScore}</b> {highScore === 1 ? 'hat' : 'hats'}!
      </p>
    </DialogContent>
  )
}

const scale = window.devicePixelRatio || 2

type CanvasProps = {
  id: string
  width: number
  height: number
  styleWidth: number
  styleHeight: number
  onContext: (ctx: CanvasRenderingContext2D) => void
}

class Canvas extends Component<CanvasProps> {
  ref: React.RefObject<HTMLCanvasElement>

  constructor(props: CanvasProps) {
    super(props)
    this.ref = createRef()
  }

  override componentDidMount() {
    const canvas = this.ref.current
    if (canvas) {
      const ctx = canvas.getContext('2d', { alpha: false })
      if (!ctx) {
        return
      }

      this.props.onContext(ctx)
    }
  }

  override render() {
    return (
      <canvas
        {...{
          id: this.props.id,
          ref: this.ref,
          width: this.props.width,
          height: this.props.height,
          style: {
            width: this.props.styleWidth,
            height: this.props.styleHeight,
          },
        }}
      />
    )
  }
}

function gameView(model: Model, dispatch: Dispatch<Msg>) {
  const playerStats: Record<string, number> = {}
  for (const playerId of Object.values(model.minions)) {
    playerStats[playerId] = (playerStats[playerId] || 0) + 1
  }

  const [players] = splitPlayersAndSpectators(model.players)
  const rankedPlayers = players.sort((a, b) => {
    return (playerStats[b.id] || 0) - (playerStats[a.id] || 0)
  })

  const timeWhenPreGameWillEnd = model.gameStartedAt + preGameDuration
  const timeWhenGameWillEnd =
    model.gameStartedAt + (preGameDuration + gameDuration)
  const preGameTimeRemaining = clamp(
    timeWhenPreGameWillEnd - model.lastGameTick,
    0,
    preGameDuration
  )

  const timeRemaining = preGameTimeRemaining
    ? gameDuration
    : clamp(
        timeWhenGameWillEnd - (model.gameFinishedAt || model.lastGameTick),
        0,
        gameDuration
      )

  const gameOver = timeRemaining === 0 || model.gameFinishedAt
  const host = model.players.find((player) => player.id === model.gameHostId)
  const self = model.players.find((player) => player.id === model.playerId)
  const isSelfHost = model.playerId === model.gameHostId
  const isSelfPlayer = !!(self && self.type === 'player')

  const playerHatMap: Record<GamePlayer['id'], Hat> = {}
  for (const player of players) {
    playerHatMap[player.id] = player.hat
  }

  return (
    <>
      <Main style={{ position: 'relative' }}>
        <GameFrame
          id="game-frame"
          onClick={
            isSelfPlayer
              ? (event) => {
                  event.stopPropagation()
                  const canvas = document.getElementById('game-canvas')
                  if (!canvas) {
                    return
                  }

                  const { minionSize, minionPhysics } = model
                  if (!(minionSize && minionPhysics)) {
                    return
                  }

                  const { clientX, clientY } = event
                  const rect = canvas.getBoundingClientRect()

                  const relativeX = (clientX - rect.x) * scale
                  const relativeY = (clientY - rect.y) * scale

                  for (const minionId of minions) {
                    if (model.playMode === 'elimination') {
                      if (
                        model.minions[minionId] &&
                        model.minions[minionId] !== self.id
                      ) {
                        continue
                      }
                    }

                    const physics = minionPhysics?.[minionId]
                    if (!physics) {
                      continue
                    }

                    const withinX =
                      relativeX > physics.x &&
                      relativeX < physics.x + minionSize.width
                    if (!withinX) {
                      continue
                    }

                    const withinY =
                      relativeY > physics.y &&
                      relativeY < physics.y + minionSize.height
                    if (withinY) {
                      dispatch({
                        type: 'minion_click',
                        minionId,
                      })
                      return
                    }
                  }
                }
              : undefined
          }
        >
          {model.viewPort && model.canvasViewPort && (
            <Canvas
              id="game-canvas"
              width={model.canvasViewPort.width}
              height={model.canvasViewPort.height}
              styleWidth={model.viewPort.width}
              styleHeight={model.viewPort.height}
              onContext={(ctx) => {
                dispatch({ type: 'update_canvas_ctx', ctx })
              }}
            />
          )}
        </GameFrame>
        {preGameTimeRemaining ? (
          <ModalBackdrop>
            <FadeInDialog>
              <DialogContent>
                <p>
                  Get ready to hat!
                  <CountdownClock
                    {...{
                      timeRemaining: preGameTimeRemaining,
                      showMinutes: false,
                    }}
                  />
                </p>
              </DialogContent>
            </FadeInDialog>
          </ModalBackdrop>
        ) : undefined}
        {gameOver && (
          <ModalBackdrop>
            <FadeInDialog>
              {gameOverContent(players, playerStats)}
              <DialogFooter>
                {isSelfHost ? (
                  <Button
                    title="Return to lobby"
                    isEnabled={!model.isResettingGame}
                    onClick={() => dispatch({ type: 'reset_game' })}
                  />
                ) : (
                  <p>
                    Waiting on {host?.name || 'the host'} to end the game...
                  </p>
                )}
              </DialogFooter>
            </FadeInDialog>
          </ModalBackdrop>
        )}
      </Main>
      <Side>
        <Header>Mad Hatter</Header>
        <TimeBox>
          <ProgressBar
            style={{ width: `${(1 - timeRemaining / gameDuration) * 100}%` }}
          />
          <label>{gameOver ? 'Game over' : 'Time remaining'}</label>
          <CountdownClock {...{ timeRemaining, showMinutes: true }} />
        </TimeBox>

        <PlayerList>
          {rankedPlayers.map((player, index) => {
            return (
              <PlayerListItem
                {...{
                  key: player.id,
                  name: player.name,
                  hat: player.hat,
                  leader: index === 0,
                  score: playerStats[player.id] || 0,
                }}
              />
            )
          })}
        </PlayerList>
      </Side>
    </>
  )
}

function rafSub(fps: number): Subscription<number> {
  let dispatch: Dispatch<number> | undefined
  let fpsInterval = 1000 / fps
  let lastTick: DOMHighResTimeStamp = 0

  function loop(now: DOMHighResTimeStamp) {
    if (!dispatch) {
      return
    }

    window.requestAnimationFrame(loop)

    if (now - lastTick > fpsInterval) {
      lastTick = now
      dispatch(now)
    }
  }

  return {
    effect(dispatchFn) {
      dispatch = dispatchFn
      window.requestAnimationFrame(loop)
    },
    cancel() {
      dispatch = undefined
    },
  }
}

const hatTilt = 4 * (Math.PI / 180)
const hatImageDict: Partial<Record<Hat, HTMLImageElement>> = {}

// A little padding between hats to avoid overlap
const hatSpace = 10

let offscreenCanvas: HTMLCanvasElement = document.createElement('canvas')
let offscreenCache: Partial<Record<Hat, boolean>> = {}

function getHatSize(minionSize: Size): number {
  return Math.floor(minionSize.width * 0.7)
}

function prepOffscreenHatCanvas(minionSize: Size) {
  const hatSize = getHatSize(minionSize)

  offscreenCanvas.width = hats.length * (hatSize + hatSpace)
  offscreenCanvas.height = hatSize
  offscreenCache = {}
}

function loadHatImage(hat: Hat, hatSize: number): HTMLImageElement | 'prepped' {
  if (offscreenCache[hat]) {
    return 'prepped'
  }

  const hatImage = hatImageDict[hat]
  if (!hatImage) {
    const newImage = new Image()
    newImage.src = hatImageSrcDict[hat]
    hatImageDict[hat] = newImage
    return newImage
  }

  if (!hatImage.complete) {
    return hatImage
  }

  const index = hats.indexOf(hat)
  const ctx = offscreenCanvas.getContext('2d')
  if (!ctx) {
    return hatImage
  }

  const halfHat = Math.floor(hatSize / 2)
  ctx.setTransform(1, 0, 0, 1, 0, 0)
  ctx.translate(index * (hatSize + hatSpace) + halfHat, halfHat)
  ctx.rotate(hatTilt)
  ctx.drawImage(hatImage, -halfHat, -halfHat, hatSize, hatSize)

  offscreenCache[hat] = true
  return 'prepped'
}

function drawFrame(ctx: CanvasRenderingContext2D, model: Model) {
  const { minionPhysics, minionSize, canvasViewPort } = model
  if (!(minionPhysics && minionSize && canvasViewPort)) {
    return
  }

  const [players] = splitPlayersAndSpectators(model.players)
  const playerHatMap: Record<GamePlayer['id'], Hat> = {}
  for (const player of players) {
    playerHatMap[player.id] = player.hat
  }

  ctx.fillStyle = '#f8fafe'
  ctx.fillRect(0, 0, canvasViewPort.width, canvasViewPort.height)

  const { width, height } = minionSize
  const headRadius = width / 2

  ctx.fillStyle = '#ddd'
  ctx.beginPath()
  for (const minionId of minions) {
    const physics = minionPhysics[minionId]
    if (!physics) {
      continue
    }

    const { x, y } = physics

    ctx.moveTo(x, y + headRadius)
    ctx.arc(x + headRadius, y + headRadius, headRadius, Math.PI, 0)
    ctx.lineTo(x + width, y + height)
    ctx.lineTo(x, y + height)
    ctx.lineTo(x, y + headRadius)
  }
  ctx.fill()

  const hatSize = getHatSize(minionSize)
  for (const minionId of minions) {
    const physics = minionPhysics[minionId]
    if (!physics) {
      continue
    }

    const playerId = model.minions[minionId]
    const hat = playerId ? playerHatMap[playerId] : undefined
    if (!hat) {
      continue
    }

    const { x, y } = physics
    const hatImage = loadHatImage(hat, hatSize)
    const dx = x + width - hatSize
    const dy = y - (hatSize * 3) / 4

    if (hatImage === 'prepped') {
      ctx.drawImage(
        offscreenCanvas,

        // source
        (hatSize + hatSpace) * hats.indexOf(hat),
        0,
        hatSize,
        hatSize,

        // destination
        dx,
        dy,
        hatSize,
        hatSize
      )
    } else {
      ctx.drawImage(hatImage, dx, dy, hatSize, hatSize)
    }
  }
}
