// import { IconName as _IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type IconProps = {
  name: IconName
  size?: 'large'
  color?: 'light'
}

export type IconName = string // _IconName

export function Icon({ name, color, size }: IconProps) {
  const sizeType = size === 'large' ? '3x' : undefined
  const colorType = color === 'light' ? '#555' : undefined

  return (
    <FontAwesomeIcon
      {...{ icon: name as any, size: sizeType, style: { color: colorType } }}
    />
  )
}
