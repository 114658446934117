import React, { Component, ReactNode } from 'react'
import { Icon, IconName } from './icon'
import { CardSection } from './card'
import { Split, SplitPriority } from './spacing'
import styled, { css } from 'styled-components'

const StyledButton = styled.button<{
  ref?: React.ForwardedRef<HTMLButtonElement>
  disabled: boolean
  isBar?: boolean
  size?: 'compact'
  kind?: 'normal' | 'danger' | 'inverted' | 'primary' | 'success'
}>`
  display: inline-block;
  border-radius: 0.15rem;
  font-weight: 600;
  font-family: inherit;
  text-align: center;
  outline: none;
  text-decoration: none;

  ${(props) =>
    props.size === 'compact'
      ? css`
          font-size: 1rem;
          padding: 0.25rem 0.4rem;
        `
      : css`
          font-size: 1rem;
          padding: 0.5rem 0.75rem;
        `}

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : undefined}

  &:focus {
    box-shadow: 0 0 2px #08f, 0 0 0 2px rgb(194, 226, 255);
  }

  ${(props) =>
    props.isBar &&
    css`
      display: block;
      width: 100%;
    `}

  ${(props) =>
    (!props.kind || props.kind === 'normal' || props.kind === 'danger') &&
    css`
      background-color: rgb(238, 238, 238);
      background-image: linear-gradient(rgb(252, 252, 252), rgb(238, 238, 238));
      color: #24292e;
      border: 1px solid rgba(27, 31, 35, 0.2);

      &:hover {
        background-color: #e6ebf1;
        background-image: linear-gradient(-180deg, #f0f3f6 0%, #e6ebf1 90%);
        border-color: rgba(27, 31, 35, 0.35);
      }

      &:active {
        background-color: #e9ecef;
        background-image: none;
        border-color: rgba(27, 31, 35, 0.35);
        box-shadow: inset 0 0.15em 0.3em rgba(27, 31, 35, 0.15);
      }

      &:focus {
        border-color: #08f;
        background-color: #fff;
        box-shadow: 0 0 0 2px rgb(0 136 255 / 25%);
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active {
        background-color: rgb(243 241 241 / 50%);
        box-shadow: none;
        background-image: none;
        color: #545454;
        border-color: #e9e9e9;
      }
    `}

  ${(props) =>
    props.kind === 'danger' &&
    css`
      color: #cb2431;

      &:hover {
        color: #fff;
        background-color: #cb2431;
        background-image: linear-gradient(-180deg, #de4450 0%, #cb2431 90%);
        border-color: rgba(27, 31, 35, 0.5);
      }

      &:active {
        color: #fff;
        background-color: #b5202c;
        background-image: none;
        border-color: rgba(27, 31, 35, 0.5);
        box-shadow: inset 0 0.15em 0.3em rgba(27, 31, 35, 0.15);
      }

      &:disabled {
        color: rgba(203, 36, 49, 0.4);
        background-color: #eff3f6;
        background-image: none;
        border-color: rgba(27, 31, 35, 0.2);
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.kind === 'inverted' &&
    css`
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
        color: rgba(255, 255, 255, 0.8);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.03);
      }
    `}

  ${(props) =>
    (props.kind === 'primary' || props.kind === 'success') &&
    css`
      color: #fff;
      background-color: #28a745;
      background-image: linear-gradient(-180deg, #34d058 0%, #28a745 90%);
      border: 1px solid rgba(27, 31, 35, 0.2);
      text-shadow: rgba(0, 0, 0, 0.15) 0px -1px 0px;

      &:hover {
        background-color: #269f42;
        background-image: linear-gradient(-180deg, #2fcb53 0%, #269f42 90%);
        border-color: rgba(27, 31, 35, 0.5);
      }

      &:active {
        background-color: #279f43;
        background-image: none;
        border-color: rgba(27, 31, 35, 0.5);
        box-shadow: inset 0 0.15em 0.3em rgba(27, 31, 35, 0.15);
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active { {
        background-color: #6bb97a;
        background-image: none;
        border: 1px solid #429f53;
        box-shadow: none;
      }
    `}
`

const StyledButtonLink = StyledButton.withComponent('a')

const IconSlot = styled.div`
  margin-right: 5px;
  opacity: 0.8;
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonTitle = styled.div``

type ButtonProps = {
  icon?: IconName
  title: string
  onClick?: () => void
  isEnabled: boolean
}

type ButtonLinkProps = ButtonProps & {
  href: string | undefined
  downloadFileName?: string
  isExternal?: boolean
}

export function ButtonLink(props: ButtonLinkProps) {
  const {
    href,
    downloadFileName,
    isExternal,
    icon,
    title,
    onClick,
    isEnabled,
    ...otherProps
  } = props

  const buttonProps = {
    disabled: !isEnabled || !href,
    children: (
      <ButtonContent>
        {icon && (
          <IconSlot>
            <Icon name={icon} />
          </IconSlot>
        )}
        <ButtonTitle>{title}</ButtonTitle>
      </ButtonContent>
    ),
    ...otherProps,
  }

  return (
    <StyledButtonLink
      {...{
        ...buttonProps,
        target: isExternal ? '_blank' : undefined,
        download: downloadFileName,
        href,
      }}
    />
  )
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { icon, title, onClick, isEnabled, ...otherProps } = props
    const buttonProps = {
      ref,
      disabled: !isEnabled,
      children: (
        <ButtonContent>
          {icon && (
            <IconSlot>
              <Icon name={icon} />
            </IconSlot>
          )}
          <ButtonTitle>{title}</ButtonTitle>
        </ButtonContent>
      ),
      ...otherProps,
    }

    return (
      <StyledButton
        {...{
          ...buttonProps,
          onClick: (event) => {
            if (onClick) {
              event.preventDefault()
              onClick()
            }
          },
        }}
      />
    )
  }
)

export class Button2 extends Component<ButtonProps> {
  override render() {
    const { icon, title, onClick, isEnabled, ...buttonProps } = this.props

    // let iconProps = icon
    // if (typeof icon === 'string') {
    //   iconProps = { name: icon }
    // }

    const props = {
      disabled: !isEnabled,
      children: (
        <ButtonContent>
          {icon && (
            <IconSlot>
              <Icon name={icon} />
            </IconSlot>
          )}
          <ButtonTitle>{title}</ButtonTitle>
        </ButtonContent>
      ),
      ...buttonProps,
    }

    return (
      <StyledButton
        {...{
          ...props,
          onClick: (event) => {
            if (onClick) {
              event.preventDefault()
              onClick()
            }
          },
        }}
      />
    )
  }
}

export const ButtonSet = styled.div`
  ${StyledButton} {
    margin: 0px 5px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`

export class FileButton extends Component<
  ButtonProps & {
    accept: string
    onFile: (file: File) => void
  }
> {
  fileInput?: HTMLInputElement
  fileListener?: () => void

  override componentDidMount() {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = this.props.accept
    fileInput.setAttribute('style', 'display: none')
    this.fileListener = () => {
      const file = (fileInput.files || [])[0]
      if (file) {
        this.props.onFile(file)
        fileInput.value = ''
      }
    }
    fileInput.addEventListener('change', this.fileListener)
    this.fileInput = fileInput
  }

  override componentWillUnmount() {
    if (this.fileInput) {
      if (this.fileListener) {
        this.fileInput.removeEventListener('change', this.fileListener)
      }
      this.fileInput = undefined
    }
  }

  override render() {
    return (
      <Button
        {...{
          ...this.props,
          onClick: () => {
            if (this.fileInput) {
              this.fileInput.click()
            }
          },
        }}
      />
    )
  }
}

export function ButtonSection({
  alternativeButtons,
  children,
}: {
  alternativeButtons?: ReactNode
  children: ReactNode
}) {
  return (
    <CardSection>
      <Split>
        <ButtonSet>{children}</ButtonSet>
        <SplitPriority />
        {alternativeButtons && <ButtonSet>{alternativeButtons}</ButtonSet>}
      </Split>
    </CardSection>
  )
}
