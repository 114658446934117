import styled, { css } from 'styled-components'
import { LabeledFieldProps, SandwichLabeledField } from './field'

const BaseSelect = styled.select<{ sizeStyle?: 'compact' }>`
  margin: 0;
  font-family: inherit;
  cursor: inherit;
  line-height: inherit;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 0.15rem;
  ${(props) =>
    props.sizeStyle === 'compact'
      ? css`
          padding: 0.25rem 0.15rem 0.25rem 0.3rem;
        `
      : css`
          padding: 0.5rem 0.3rem 0.5rem 0.5rem;
        `}

  outline: none;
  font-size: 1rem;
  font-weight: 600;

  color: #24292e;
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(rgb(252, 252, 252), rgb(238, 238, 238));

  &:hover {
    background-color: #e6ebf1;
    background-image: linear-gradient(-180deg, #f0f3f6 0%, #e6ebf1 90%);
    border-color: rgba(27, 31, 35, 0.35);
  }

  &:focus {
    border-color: #08f;
    background-color: #fff;
    box-shadow: 0 0 0 2px rgb(0 136 255 / 25%);
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    cursor: not-allowed;
    background-color: rgb(243 241 241 / 50%);
    box-shadow: none;
    background-image: none;
    color: #545454;
    border-color: #e9e9e9;
  }
`

type SelectProps = {
  size?: 'compact'
  isEnabled: boolean
  isRequired?: boolean
  value: string
  options: { value: string; title: string }[]
  onValue: (value: string) => void
}

export function Select(props: SelectProps) {
  return (
    <BaseSelect
      {...{
        sizeStyle: props.size,
        disabled: !props.isEnabled,
        required: props.isRequired,
        value: props.value,
        onChange(event) {
          props.onValue(event.target.value)
        },
      }}
    >
      {props.options.map((option) => (
        <option
          {...{
            key: option.value,
            value: option.value,
          }}
        >
          {option.title}
        </option>
      ))}
    </BaseSelect>
  )
}

export function SelectField(props: SelectProps & LabeledFieldProps) {
  const { title, description, noLabelWrap, noPadding, ...textboxProps } = props
  return (
    <SandwichLabeledField
      {...{
        title,
        description,
        isEnabled: props.isEnabled,
        noPadding,
        noLabelWrap,
      }}
    >
      <Select {...textboxProps} />
    </SandwichLabeledField>
  )
}
