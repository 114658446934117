// NOTE: We accept the value so it easier to debug
// what caused the exhaustive check to fail in test.
export class AbsurdError<A> extends TypeError {
  readonly value: A

  constructor(message: string, value: A) {
    super(message)

    this.value = value
  }
}

export function absurd(value: never, message?: string): never {
  throw new AbsurdError(
    message || 'Unexpected value passed to `absurd`.',
    value
  )
}

export function must<A>(value: A | undefined, message?: string): A {
  if (value !== undefined) {
    return value
  }

  throw new AbsurdError(
    message || 'Unexpected `undefined` value in `must`',
    value
  )
}

/** When something ought to be impossible but it cannot be proven
 * using the type system, most often when dealing with async code.
 *
 * Prefer to use `absurd` wherever possible.
 */
export function shouldBeImpossible(value: unknown, reason: string): never {
  throw new AbsurdError(`should_be_impossible: ${reason}`, value)
}
