import { ReactNode } from 'react'

type FormProps = {
  onSubmit: () => void
  children: ReactNode
}

export function Form(props: FormProps) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit()
      }}
    >
      {props.children}
    </form>
  )
}
