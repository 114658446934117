import styled, { css } from 'styled-components'

export const Split = styled.div<{
  verticalCenter?: boolean
  horizontal?: 'pack' | 'center'
}>`
  display: flex;
  ${(props) =>
    props.horizontal
      ? props.horizontal === 'center'
        ? css`
            justify-content: center;
          `
        : css`
            justify-content: flex-start;
          `
      : css`
          justify-content: space-between;
        `}
  ${(props) =>
    props.verticalCenter
      ? css`
          align-items: center;
        `
      : css`
          align-items: flex-start;
        `}
`

export const SplitPriority = styled.div<{ stretch?: boolean }>`
  flex: 1;

  ${(props) =>
    props.stretch
      ? css`
          display: flex;
          align-self: stretch;
        `
      : undefined}
`

export const SplitBlock = styled.div`
  width: 15px;
  height: 15px;
`

export const Pad = styled.div`
  margin: 15px 0em;

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`
