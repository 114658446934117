import React, { ReactNode, RefObject } from 'react'
import Clipboard from 'clipboard'
import { Button } from './button'
import { TextField } from './text-field'
// import { Button } from './button'
// import { TextField } from './text-field'
// import styled, { css } from 'styled-components'

// const PartialSnippetText = styled.span<{ width?: number }>`
//   display: inline-block;
//   box-shadow: inset -2px 0px 2px rgba(255, 255, 255, 0.5);
//   white-space: nowrap;
//   text-overflow: hidden;
//   overflow: hidden;

//   ${(props) =>
//     css`
//       max-width: ${props.width || 120}px;
//     `}
// `

// const PartialSnippetBlur = styled.span`
//   display: inline-block;
//   box-shadow: 0 0 20px 10px #fff;
//   align-self: stretch;
// `

// const PartialSnippetContainer = styled.span`
//   display: flex;
//   overflow: hidden;
//   justify-content: flex-start;
//   align-items: flex-start;
// `

// export function PartialSnippet({ width, masked, children }) {
//   if (masked) {
//     children = new Array(20).fill('•').join('')
//   }

//   return (
//     <PartialSnippetContainer>
//       <PartialSnippetText width={width}>{children}</PartialSnippetText>
//       <PartialSnippetBlur />
//     </PartialSnippetContainer>
//   )
// }

type CopyFieldProps = {
  title: string
  description: string | undefined
  value: string
  isSecret: boolean
  isEnabled: boolean
  isMultipleLines?: boolean
}

// Do nothing; TODO: refactor so the text field is nicer
const onValue = () => {}

export function CopyField({
  title,
  description,
  value,
  isSecret,
  isEnabled,
  isMultipleLines,
}: CopyFieldProps) {
  const mask = value.replace(/./g, '•')

  return (
    <>
      <TextField
        {...{
          title,
          description,
          value: isSecret ? mask : value,
          onValue,
          isEnabled: false,
          isMultipleLines,
        }}
      />

      <CopyProvider<HTMLButtonElement> value={value}>
        {(props) => (
          <Button
            {...{
              title: 'Copy to clipboard',
              isEnabled,
              ...props,
            }}
          />
        )}
      </CopyProvider>
    </>
  )
}

type CopyProviderProps<E extends HTMLElement> = {
  value: string
  children: (copyProps: {
    ref: RefObject<E>
    'data-clipboard-text': string
  }) => ReactNode
}

export class CopyProvider<E extends HTMLElement> extends React.Component<
  CopyProviderProps<E>
> {
  copyButton: RefObject<E>
  clipboard: Clipboard | undefined

  constructor(props: CopyProviderProps<E>) {
    super(props)

    this.copyButton = React.createRef()
  }

  override componentDidMount() {
    const element = this.copyButton.current
    if (element) {
      this.clipboard = new Clipboard(element)
    }
  }

  override componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  }

  override render() {
    const { value, children } = this.props
    return children({
      ref: this.copyButton,
      'data-clipboard-text': value,
    })
  }
}
